import { AddEventFormValues, EventModel } from './Events.interface'
import { DateTime, Duration } from 'luxon'
import constants from '@/utils/Constants.json'
import {
  EventDates,
  iterateDates,
  iterateMeetingOccurrences,
  MeetingOccurrence
} from '@/utils/occurrences'

const validateLink =
  /(([\w]+:)\/\/)(([\d\w]|%[a-fA-f\d]{2,2})+(:([\d\w]|%[a-fA-f\d]{2,2})+)?@)?([\d\w][-\d\w]{0,253}[\d\w]\.)+[\w]{2,63}(:[\d]+)?(\/([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)*(\?(&?([-+_~.\d\w]|%[a-fA-f\d]{2,2})=?)*)?(#([-+_~.\d\w]|%[a-fA-f\d]{2,2})*)?/

const TIME_INTERVAL_PERIOD = 30 // in minutes
const USA_LOCALE = 'en-US'
const GREATBRITAIN_LOCALE = 'en-GB'

const retakerValue = 'Retaker'
const defaultDateFormat = 'yyyy-LL-dd'
const dateDayUnit = 'day'

export const US_TIMEZONE_MAPPING: Record<string, string> = Object.freeze({
  EST: 'America/New_York',
  CST: 'America/Chicago',
  MST: 'America/Denver',
  PST: 'America/Los_Angeles',
  BST: 'Europe/London',
  GMT: 'GMT'
})

export function getCurrentTimezone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone
}

export function isBritishTimeZone(timeZone: string) {
  return timeZone === 'BST' || timeZone === 'Europe/London'
}

export function getTimeZoneOffsetISO(timeZone: string = getCurrentTimezone()) {
  const millisecondPerSecond = 1000
  const secondsPerHour = 3600
  const offset = getTimeZoneOffset(timeZone) / millisecondPerSecond
  const sign = offset < 0 ? '-' : '+'
  const hours = Math.floor(Math.abs(offset) / secondsPerHour)
  const minutes = Math.floor(Math.abs(offset) / 60) - hours * 60
  return `${sign}${padString(hours)}${padString(minutes)}`
}

export function getTimeZoneOffset(timeZone: string = getCurrentTimezone()) {
  const date = new Date()
  const utcDate = new Date(
    date.toLocaleString(USA_LOCALE, { timeZone: 'UTC' })
  )
  const tzDate = new Date(
    date.toLocaleString(USA_LOCALE, {
      timeZone: US_TIMEZONE_MAPPING[timeZone] ?? timeZone
    })
  )
  return tzDate.getTime() - utcDate.getTime()
}

const generateTimeIntervals = () => {
  const times = []
  let tt = 0
  const ap = ['AM', 'PM']

  const minutesPerDay = 24 * 60

  for (let i = 0; tt < minutesPerDay; i++) {
    const hh = Math.floor(tt / 60)
    const mm = tt % 60
    times[i] = {
      text:
        padString(hh % 12 || 12) +
        ':' +
        padString(mm) +
        ' ' +
        ap[Math.floor(hh / 12)],
      value: padString(hh) + ':' + padString(mm)
    }
    tt += TIME_INTERVAL_PERIOD
  }

  return times
}

const getRoundedCurrentTime = (minuteAddition?: number) => {
  const currentDate = new Date()
  let roundedMinutes: number | Date = currentDate.getMinutes()
  if (roundedMinutes > TIME_INTERVAL_PERIOD) {
    const diff = 60 - roundedMinutes
    roundedMinutes = new Date(currentDate.getTime() + diff * 60000)
  } else {
    const diff = TIME_INTERVAL_PERIOD - roundedMinutes
    roundedMinutes = new Date(currentDate.getTime() + diff * 60000)
  }

  if (minuteAddition) {
    // this is for the end time to set the default value as + 30 minutes from the start time
    roundedMinutes = new Date(
      roundedMinutes.getTime() + minuteAddition * 60000
    )
  }

  return `${roundedMinutes
    .getHours()
    .toString()
    .padStart(2, '0')}:${roundedMinutes
    .getMinutes()
    .toString()
    .padStart(2, '0')}`
}

const padString = (val: string | number) => val.toString().padStart(2, '0')

const formatDate = (dateValue: Date | undefined | string) => {
  if (!dateValue) return ''
  const dateItem = new Date(dateValue)
  const day = padString(dateItem.getDate())
  const month = padString(dateItem.getMonth() + 1)
  const year = dateItem.getFullYear()
  return `${year + '-' + month + '-' + day}`
}

const getDateFromUTC = (
  dateString: string,
  timeString: string,
  timezone: string
) => {
  if (!dateString || !timeString) return ''

  const utcDate = DateTime.fromISO(`${dateString}T${timeString}Z`, {
    zone: 'utc'
  })

  const relativeDate = utcDate.setZone(US_TIMEZONE_MAPPING[timezone])

  return relativeDate
}

const getIsoDateFromUTC = (
  dateString: string,
  timeString: string,
  timezone: string
) => {
  const date = getDateFromUTC(dateString, timeString, timezone)
  return date ? date.toISODate() : date
}

const getTimeFromUTC = (
  dateString: string,
  timeString: string,
  timezone: string
) => {
  if (!dateString || !timeString) return ''

  const utcDate = DateTime.fromISO(`${dateString}T${timeString}Z`, {
    zone: 'utc'
  })

  const relativeDate = utcDate.setZone(US_TIMEZONE_MAPPING[timezone])

  return relativeDate.toISOTime({
    includeOffset: false,
    suppressSeconds: true
  })
}

const formatTime = (dateTime: DateTime) =>
  dateTime.toLocaleString({ hour: 'numeric', minute: 'numeric', hour12: true }).toUpperCase()

export function formatUTCTime (dateTime: DateTime) {
  return dateTime.toISOTime({
    includeOffset: false
  })
}

const formatUTCDate = (dateTime: DateTime) => dateTime.toISODate()

export function convertEventScheduleToUTC(
  date: string,
  time: string,
  timeZone: string
) {
  const relativeDate = DateTime.fromISO(`${date}T${time}`, {
    zone: US_TIMEZONE_MAPPING[timeZone]
  })

  return relativeDate.toUTC()
}

export function getProducts(eventInfo: EventModel): string {
  if (!eventInfo.products)
  {
    return ''
  }

  return constants.Products.filter((product) =>
    eventInfo.products.some((eventProduct) => 
      eventProduct === product.value
    ))
    .map((product) => product.label)
    .join(', ')
}

export function createDateFormatter(event: EventModel) {
  const localization = isBritishTimeZone(event.timeZone)
    ? GREATBRITAIN_LOCALE
    : USA_LOCALE
  const isoStartDate = event.startDate + 'T' + event.startTime + 'Z'
  const isoEndDate = event.endDate + 'T' + event.endTime + 'Z'
  const startDateTime = DateTime.fromISO(isoStartDate)
    .toLocal()
    .setLocale(localization)
    .setZone(US_TIMEZONE_MAPPING[event.timeZone])
  const endDateTime = DateTime.fromISO(isoEndDate)
    .toLocal()
    .setLocale(localization)
    .setZone(US_TIMEZONE_MAPPING[event.timeZone])

  function formatDateRange(): string {
    const startYearText = startDateTime.toLocaleString({
      year: 'numeric'
    })
    const endYearText = endDateTime.toLocaleString({
      year: 'numeric'
    })
    const startMonthDayText = startDateTime.toLocaleString({
      month: 'short',
      day: 'numeric'
    })
    const endMonthDayText = endDateTime.toLocaleString({
      month: 'short',
      day: 'numeric'
    })
    const dayOfWeek = startDateTime.toLocaleString({
      weekday: 'long'
    })

    if (startYearText === endYearText) {
      if (startMonthDayText === endMonthDayText) {
        return `${dayOfWeek} | ${startMonthDayText} ${startYearText}`
      }
      return `${dayOfWeek} | ${startMonthDayText} - ${endMonthDayText} ${startYearText}`
    }

    return `${dayOfWeek} | ${startMonthDayText} ${startYearText} - ${endMonthDayText} ${endYearText}`
  }

  function formatTimeRange(): string {
    return formatTime(startDateTime) + ' - ' + formatTime(endDateTime)
  }

  function formatStartWeekday(): string {
    return startDateTime.toLocaleString({
      weekday: 'long'
    })
  }

  return {
    formatDateRange,
    formatTimeRange,
    formatStartWeekday,
    eventStartDate: startDateTime,
    eventEndDate: endDateTime
  }
}

function buildEventOccurrencesIterable(formFields: AddEventFormValues) {
  return iterateMeetingOccurrences({
    repeating: formFields.repeating,
    startDate: DateTime.fromISO(formFields.startDate),
    endDate: DateTime.fromISO(formFields.endDate),
    startTime: Duration.fromISOTime(formFields.startTime),
    endTime: Duration.fromISOTime(formFields.endTime),
    dateChanges: {
      added: (formFields.datesChanges?.added ?? []).map((element: string) =>
        DateTime.fromISO(element)
      ),
      removed: (formFields.datesChanges?.removed ?? []).map((element: string) =>
        DateTime.fromISO(element)
      )
    }
  })
    .select((element: MeetingOccurrence) => element.start)
}

function buildEventOccurrences(formFields: AddEventFormValues) {
  return buildEventOccurrencesIterable(formFields)
    .select((element: DateTime) => ({
      start: element.toFormat(defaultDateFormat),
      end: element.toFormat(defaultDateFormat),
      allDay: false,
      editable: false,
      deletable: false
    }))
    .toArray()
}

const constructAddEventBody = (formFields: AddEventFormValues) => {
  const endTime = convertEventScheduleToUTC(
    formFields.endDate,
    formFields.endTime,
    formFields.timeZone
  )

  const startTime = convertEventScheduleToUTC(
    formFields.startDate,
    formFields.startTime,
    formFields.timeZone
  )

  const {
    products,
    jurisdictions,
    coachId,
    schoolId,
    description,
    meetingLink,
    timeZone,
    repeating,
    attendees,
    maxAttendees,
    isHidden,
    tags,
    datesChanges,
    title,
    salesforceClassroomId,
    recordingLink,
    year,
    period
  } = formFields

  if (datesChanges) {
    datesChanges.added = [
      ...new Set(
        datesChanges.added.map((element: string) => {
          return formatUTCDate(
            convertEventScheduleToUTC(
              element,
              formFields.startTime,
              formFields.timeZone
            )
          )
        })
      )
    ]
    datesChanges.removed = [
      ...new Set(
        datesChanges.removed.map((element: string) => {
          return formatUTCDate(
            convertEventScheduleToUTC(
              element,
              formFields.startTime,
              formFields.timeZone
            )
          )
        })
      )
    ]
  }

  return {
    products,
    jurisdictions,
    coachId,
    schoolId,
    description,
    meetingLink,
    timeZone,
    repeating,
    attendees,
    maxAttendees,
    isHidden,
    tags,
    datesChanges,
    startDate: formatUTCDate(startTime),
    endDate: formatUTCDate(endTime),
    startTime: formatUTCTime(startTime),
    endTime: formatUTCTime(endTime),
    title,
    salesforceClassroomId,
    recordingLink,
    year,
    period
  }
}

export {
  validateLink,
  generateTimeIntervals,
  getRoundedCurrentTime,
  TIME_INTERVAL_PERIOD,
  formatDate,
  getTimeFromUTC,
  getDateFromUTC,
  getIsoDateFromUTC,
  constructAddEventBody,
  formatTime,
  buildEventOccurrences,
  buildEventOccurrencesIterable,
  retakerValue,
  defaultDateFormat,
  dateDayUnit
}
