import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b7049fca"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "event-title" }
const _hoisted_2 = { class: "event-time" }
const _hoisted_3 = {
  key: 0,
  class: "badge badge-primary"
}
const _hoisted_4 = { key: 1 }
const _hoisted_5 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TableView = _resolveComponent("TableView")!
  const _component_DeleteConfirmationModal = _resolveComponent("DeleteConfirmationModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_TableView, {
      columns: _ctx.eventsColumns,
      data: _ctx.filteredEvents,
      "is-loading": _ctx.isLoading,
      class: "list-events-segment",
      onRowClicked: _ctx.handleRowClick
    }, {
      startDate: _withCtx(({ rowData }) => [
        _createElementVNode("h2", _hoisted_1, _toDisplayString(rowData.dayPeriod), 1),
        _createElementVNode("label", _hoisted_2, _toDisplayString(rowData.timePeriod + " (" + rowData.timeZone + ")"), 1)
      ]),
      tags: _withCtx(({ rowData }) => [
        (_ctx.hasRetakerTag(rowData))
          ? (_openBlock(), _createElementBlock("span", _hoisted_3, "Retaker"))
          : (_openBlock(), _createElementBlock("span", _hoisted_4))
      ]),
      isHidden: _withCtx(({ rowData }) => [
        _createElementVNode("label", null, _toDisplayString(_ctx.isHidden(rowData)), 1)
      ]),
      actions: _withCtx(({ rowData }) => [
        _createElementVNode("button", {
          type: "button",
          class: "btn btn-danger",
          onClick: ($event: any) => (_ctx.handleDeleteClick($event, rowData))
        }, _toDisplayString(_ctx.locales.delete_event_button_name), 9, _hoisted_5)
      ]),
      _: 1
    }, 8, ["columns", "data", "is-loading", "onRowClicked"]),
    _createVNode(_component_DeleteConfirmationModal, {
      "is-visible": _ctx.visibleDeleteModal,
      "disable-delete-button": _ctx.disableAcceptDelete,
      "item-type": "event",
      onClose: _ctx.onDeclineDelete,
      onDelete: _ctx.onAcceptDelete
    }, null, 8, ["is-visible", "disable-delete-button", "onClose", "onDelete"])
  ], 64))
}