import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-fe5e0ffe"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "event-title" }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = {
  key: 0,
  class: "w-100"
}
const _hoisted_4 = { key: 1 }
const _hoisted_5 = {
  key: 0,
  class: "w-100"
}
const _hoisted_6 = { key: 1 }
const _hoisted_7 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DocumentIcon = _resolveComponent("DocumentIcon")!
  const _component_RecordingLinkIcon = _resolveComponent("RecordingLinkIcon")!
  const _component_TableView = _resolveComponent("TableView")!
  const _component_DeleteConfirmationModal = _resolveComponent("DeleteConfirmationModal")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_TableView, {
      columns: _ctx.lsatColumns,
      data: _ctx.filteredEvents,
      "is-loading": _ctx.isLoading,
      class: "list-events-segment",
      onRowClicked: _ctx.handleRowClick
    }, {
      startDate: _withCtx(({ rowData }) => [
        _createElementVNode("label", null, _toDisplayString(rowData.dayPeriod), 1)
      ]),
      time: _withCtx(({ rowData }) => [
        _createElementVNode("label", null, _toDisplayString(rowData.timePeriod + " (" + rowData.timeZone + ")"), 1)
      ]),
      title: _withCtx(({ rowData }) => [
        _createElementVNode("label", _hoisted_1, _toDisplayString(rowData.title), 1)
      ]),
      tags: _withCtx(({ rowData }) => [
        (_ctx.hasTags(rowData))
          ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(rowData.tags, (tag) => {
              return (_openBlock(), _createElementBlock("span", {
                key: tag,
                style: _normalizeStyle(_ctx.getTagColor(tag))
              }, " (" + _toDisplayString(tag) + ") ", 5))
            }), 128))
          : (_openBlock(), _createElementBlock("span", _hoisted_2))
      ]),
      document: _withCtx(({ rowData }) => [
        (_ctx.hasDocuments(rowData))
          ? (_openBlock(), _createElementBlock("label", _hoisted_3, [
              _createVNode(_component_DocumentIcon)
            ]))
          : (_openBlock(), _createElementBlock("span", _hoisted_4))
      ]),
      recordingLink: _withCtx(({ rowData }) => [
        (_ctx.hasRecording(rowData))
          ? (_openBlock(), _createElementBlock("label", _hoisted_5, [
              _createVNode(_component_RecordingLinkIcon)
            ]))
          : (_openBlock(), _createElementBlock("span", _hoisted_6))
      ]),
      isHidden: _withCtx(({ rowData }) => [
        _createElementVNode("label", null, _toDisplayString(_ctx.isHidden(rowData)), 1)
      ]),
      actions: _withCtx(({ rowData }) => [
        _createElementVNode("button", {
          type: "button",
          class: "btn btn-danger",
          onClick: ($event: any) => (_ctx.handleDeleteClick($event, rowData))
        }, _toDisplayString(_ctx.locales.delete_event_button_name), 9, _hoisted_7)
      ]),
      _: 1
    }, 8, ["columns", "data", "is-loading", "onRowClicked"]),
    _createVNode(_component_DeleteConfirmationModal, {
      "is-visible": _ctx.visibleDeleteModal,
      "disable-delete-button": _ctx.disableAcceptDelete,
      "item-type": "event",
      onClose: _ctx.onDeclineDelete,
      onDelete: _ctx.onAcceptDelete
    }, null, 8, ["is-visible", "disable-delete-button", "onClose", "onDelete"])
  ], 64))
}