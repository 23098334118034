import locales from './Events.locales.en.json'
import { RepeatingOption } from './Events.interface'
import { TableRowItem } from '@/components/Table/TableView.interfaces'

const RepeatingEventOptions = [
    { text: locales.does_not_repeat, value: RepeatingOption.DoesNotRepeat }   
]

const eventsColumns = [
  {
    key: 'startDate',
    label: 'Events'
  },
  {
    key: 'productsNames',
    label: 'Product'
  },
  {
    key: 'jurisdictionsNames',
    label: 'Jurisdiction'
  },
  {
    key: 'capacity',
    label: 'Number Of Registrants',
    sortFunction: (a: TableRowItem, b: TableRowItem) => {
      const maxAttendees = a.maxAttendees - b.maxAttendees
      return maxAttendees ? maxAttendees : a.attendees.length - b.attendees.length
    }
  },
  {
    key: 'coachName',
    label: 'Coach'
  },
  {
    key: 'tags',
    label: 'Tags'
  },
  {
    key: 'isHidden',
    label: 'Hidden'
  }
]

export {
    RepeatingEventOptions,
    eventsColumns
}